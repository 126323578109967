<template>
  <div class="home__section">
    <section-header title="المـــــواد" link="/materials" class="" />
    <v-row>
      <!-- ########## Categories in Small Screens ######### -->
      <v-col v-if="mdAndDown" cols="12" sm="6" md="4">
        <v-menu rounded="lg" offset-y transition="slide-y-transition">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" class="categoreis-btn" rounded>
              {{ selectedItem }}
              <img src="@/assets/images/header/more-gray.svg" alt="more" />
            </v-btn>
          </template>
          <v-list dense class="categories-list menu-list">
            <v-list-item
              v-for="(item, i) in categories"
              :key="i"
              :value="item"
              @click="selectedItem = item"
              :active="selectedItem === item"

            >
              <v-list-item-title v-text="item" />
              <!-- <v-list-item-icon> -->
              <img src="~/assets/images/header/more.svg" />
              <!-- </v-list-item-icon> -->
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <!-- ########## Categories in Desktop ######### -->
      <v-col v-else cols="12" lg="3">
        <v-list dense class="categories-list">
          <v-list-item
            v-for="(item, i) in categories"
            :key="i"
            :value="item"
            @click="selectedItem = item"
            :active="selectedItem === item"

          >
            <v-list-item-title v-text="item" />
            <!-- <v-list-item-icon> -->
            <img src="~/assets/images/header/more.svg" />
            <!-- </v-list-item-icon> -->
          </v-list-item>
        </v-list>
      </v-col>
      <!-- ########## Courses List ######### -->
      <v-col cols="12" lg="9">
        <div v-if="coursesList.length === 0" class="no-courses-container">
          <span> لايوجد مواد </span>
        </div>
        <v-row class="cards">
          <v-col v-for="(course, i) in coursesList" :key="i" cols="6" md="4">
            <course-card :course="course" @onFavorite="fetch" />
          </v-col>
        </v-row>
        <!-- ########## Show More Btn ######### -->
        <v-row>
          <v-col v-if="coursesList.length < categoryCourses.length">
            <div class="more-courses">
              <v-btn
                elevation="10"
                icon
                height="max-content"
                @click="fetchMoreData"
                size="large"
              >
                <img src="~/assets/images/cards/more.svg" alt="more" />
              </v-btn>
              <p>عرض المزيد</p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script setup>
import { computed, ref, watch, onMounted } from "vue";

import CourseCard from "~/components/cards/CourseCard";
import SectionHeader from "~/components/shared/SectionHeader";

import { useHomeStore } from "~/stores/HomeStore";
import { useDisplay } from "vuetify";

const { mdAndDown } = useDisplay();
const homeStore = useHomeStore();

const selectedItem = ref("");
const coursesList = ref([]);

const home = computed(() => homeStore.home);

const allCourses = computed(() => {
  return home.value.courses !== undefined ? home.value.courses : {};
});

const categories = computed(() => {
  return Object.keys(allCourses.value);
});

const categoryCourses = computed(() => {
  return selectedItem.value ? allCourses.value[selectedItem.value] : [];
});

const setCoursesList = () => {
  const arr = [...categoryCourses.value];
  coursesList.value = arr.splice(0, 6);
};

const fetchMoreData = () => {
  if (coursesList.value.length < categoryCourses.value.length) {
    const arr2 = [...categoryCourses.value];
    coursesList.value.push(...arr2.splice(coursesList.value.length, 6));
  }
};

const fetch = async () => {
  if (!homeStore.home) {
    await homeStore.fetchHome();
  }
};

await fetch().then(() => {
  selectedItem.value = categories.value[0];
  setCoursesList();
});
// onMounted(async () => {
// });

watch(categoryCourses, () => {
  setCoursesList();
});
</script>

<style lang="scss" scoped>
.more-courses {
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
  }
}

@media (max-width: 1264px) {
  .categories-list {
    margin: 0px;
    padding: 15px 10px;
  }
}

.categoreis-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100% !important;
  background-color: #f4f4f4;
  font-size: 18px;
  font-weight: bold;
  padding: 25px 20px !important;
  .v-list-item__title {
    color: #fff;
  }
  img {
    display: block;
    margin-bottom: -4px;
  }
}
.menu-list {
  padding: 10px !important;
  margin: 0 !important;
  border-radius: 8px;
}
@media (max-width: 600px) {
  .row {
    margin: 0px !important;
  }
  .cards > div:nth-of-type(n) {
    padding-right: 0 !important;
  }
  .cards > div:nth-of-type(2n) {
    padding-left: 0 !important;
  }
}
@media (max-width: 375px) {
  .home__section {
    padding: 0px;
  }
  .card {
    min-height: 350px;
    border-radius: 18px;
    padding-bottom: 10px;

    .card__img {
      height: 131px;
    }
    .card__btns {
      margin-top: 46px !important;
    }
    .v-card__actions {
      padding-bottom: 2px;
    }
    .card__title {
      font-size: 15px;
    }
    .tax {
      font-size: 12px;
    }
    .card__sub {
      align-items: flex-start;
      font-size: 12px;
      min-height: 36px;
      div {
        margin: 0px 10px 0 5px;
      }
      .price {
        margin-right: 20px !important;
        &:before {
          top: 5px;
          right: -18px;
        }
        .old-price {
          font-size: 10px;
          margin-right: 0px;
          margin-bottom: -10px;
        }
      }
    }
    .card__btns {
      margin-top: 85px;
      display: flex;
      .add-to-cart {
        justify-content: space-around;
        padding: 18px 5px;
        margin: 0px -4px 0px 2px;
        border-radius: 10px;
        font-size: 10px;
        min-width: 70%;
        img {
          margin-left: 2px;
        }
      }
    }
    .card__des {
      font-size: 10px;
      padding: 12px 12px 12px;
    }
  }
}
.no-courses-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
</style>
