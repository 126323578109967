<template>
  <div>
    <div>
      <Alert
        :alert-visible="showAlert"
        :alert-data="alertData"
        @closeModal="isAlertClosed"
      />
      <v-card class="card">
        <div class="card__img">
          <img @click="showDetails" :src="ebook.poster">
        </div>
        <div class="card__content">
          <v-card-title @click="showDetails" class="card__title">
            {{ textLengthFilter(ebook.name, 25) }}
          </v-card-title>

          <v-card-text class="card__des">
            {{ textLengthFilter(ebook.brief_description, 100) }}
            <span class="card__line" />
            <div v-if="ebook.price !== 'hide_price'" class="card_details">
              {{ ebook.price + " ر.س" }}
              <span
                v-if="ebook.old_price && ebook.old_price !== ebook.price"
                class="old-price"
              >{{ ebook.old_price + " ر.س" }}</span>
              <br>
              <strong
                v-if="ebook.does_has_vat === true && ebook.hide_price === false"
                class="text-subtitle-2 mt-2 mr-1"
              >
                السعر شامل الضريبة :
                {{ ebook.price_with_vat_show + " ر.س" }}
              </strong>
              <br>
              {{ ebook.pages + " صفحة" }}
            </div>
          </v-card-text>
          <v-card-actions class="card__btns">
            <v-btn
              :loading="loading"
              @click="
                ebook.pay_button_title === 'مشترك'
                  ? showDetails()
                  : addToCart(ebook)
              "
              :disabled="!ebook.pay_button_status"
              class="add-book"
            >
              <img src="~/assets/images/cards/buy-book.svg" alt="buy book">
              {{ ebook.pay_button_title }}
            </v-btn>
            <div
              class="buttons-wrapper"
              :class="{ 'd-flex align-center': smAndUp }"
            >
              <CourseGiftBtn
                :is-btn-disabled="ebook.pay_button_title === 'في السلّة'"
                product-type="ebook"
                :product-data="ebook"
                @onGift="onGift"
              />
              <FavoriteBtn :item="ebook" @onFavorite="onFavorite" />
            </div>
          </v-card-actions>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';

import FavoriteBtn from '~/components/course/FavoriteBtn.vue';
import Alert from '~/components/shared/Alert.vue';
import { useCartStore } from '~/stores/CartStore';
import { useAuthStore } from '~/stores/AuthStore';

const authStore = useAuthStore();
const cartStore = useCartStore();
const props = defineProps({
  ebook: {
    type: Object,
    default: () => ({})
  }
});

const router = useRouter();
const { smAndUp } = useDisplay();
const { textLengthFilter } = useFilters(); // Destructure the filter function

const showAlert = ref(false);
const alertData = ref({});
const clicked = ref(false);
const loading = ref(false);
const isInCart = ref(false);
const cartItems = ref([]);

const isLogin = computed(() => authStore.auth.isLoggedIn);

const showDetails = () => {
  router.push({
    name: 'ebooks-id',
    params: { id: props.ebook.id }
  });
};

const addToCart = async (ebook) => {
  loading.value = true;
  if (authStore.auth.isLoggedIn) {
    try {
      const res = await cartStore.getCartDetails();
      cartItems.value = res.data.cart_items;
      isInCart.value = cartItems.value.some(ele => ele.id === ebook.id);

      if (!isInCart.value) {
        const formData = new FormData();
        formData.append('product_id', ebook.id);
        formData.append('product_type', 'ebook');
        
        const ref = useCookie("ref");
        const promo = useCookie("promo");
        if (ref.value) {
          formData.append("ref", ref.value);
        }
        if (promo.value) {
          formData.append("promo_code", promo.value);
        }

        await cartStore.addItemToCart(formData);
        setAlertData({
          message: 'تمت إضافة الكتاب للسلة بنجاح',
          success: true
        });
      } else {
        setAlertData({
          message: 'تمت إضافة الكتاب للسلة بنجاح',
          success: false
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      loading.value = false;
    }
  } else {
    setAlertData({
      message: 'يجب عليك تسجيل الدخول',
      success: false
    });
    loading.value = false;
  }
};

const onGift = (responseData) => {
  setAlertData(responseData);
};

const onFavorite = () => {
  emit('onFavorite', { value: true });
};

const setAlertData = (data) => {
  alertData.value = data;
  showAlert.value = true;
};

const isAlertClosed = (payload) => {
  if (payload.value) {
    showAlert.value = false;
  }
};

</script>
<style lang="scss" scoped>
.card {
  height: 350px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  border: none !important;
  box-shadow: none !important;
  * {
    word-break: break-word !important;
  }
  .card__img {
    width: 40%;
    img {
      cursor: pointer;
      width: 100% !important;
      height: 100%;
      box-shadow: 6px 3px 16px 0 rgba(0, 0, 0, 0.16);
    }
  }
  .card__content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 12px;
    .card__title {
      cursor: pointer;
      display: block;
      font-size: 24px;
      font-weight: bold;
    }
    // .card__des {
    //   font-size: 15px;
    //   font-weight: normal;
    //   line-height: 1.6;
    //   text-align: right;
    //   min-height: 90px;
    // }
    .card__line {
      display: block;
      width: 90%;
      height: 2px;
      margin: 10px 0;
      opacity: 0.35;
      background-color: #707070;
    }
    .card_details {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.56;
      .old-price {
        margin-right: 10px;
        font-size: 12px;
        font-weight: bold;
        line-height: 2.08;
        text-align: right;
        color: #fa9237;
        text-decoration: line-through;
      }
    }
    .card__btns {
      display: flex;
      justify-content: space-between;
      .add-book {
        padding: 24px 22px;
        border-radius: 18px;
        background-color: #f4f4f4;
        font-size: 12px;
        font-weight: bold;
        img {
          margin-left: 5px;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .card {
    .card__content {
      .card__title {
        font-size: 19px;
      }
      .card__des {
        font-size: 13px;
      }
      .card_details {
        font-size: 13px;
        .old-price {
          margin-right: 10px;
          font-size: 13px;
        }
      }
      .card__btns {
        padding: 0 10px;
        .add-book {
          padding: 19px 10px;
          font-size: 12px;
          border-radius: 12px;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .card__btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .buttons-wrapper {
    display: flex;
    gap: 10px;
    margin-top: 12px;
  }
}
@media (max-width: 600px) {
  .card {
    .card__content {
      .card__title {
        font-size: 15px;
      }
      .card__des {
        font-size: 12px;
      }
      .card_details {
        font-size: 12px;
        .old-price {
          margin-right: 10px;
          font-size: 12px;
        }
      }
      .card__btns {
        padding: 0 10px;
        .add-book {
          padding: 19px 10px;
          font-size: 12px;
          border-radius: 10px;
        }
      }
    }
  }
}

@media (max-width: 370px) {
  .card {
    .card__content {
      .card__btns {
        padding: 0 2px;
      }
    }
  }
  .favorite-btn {
    background-color: #f4f4f4 !important;
    padding: 14px 15px;
    font-size: 15px;
    border-radius: 10px;
  }
}
</style>
