Z
<template>
  <div class="home__section">
    <section-header title="الكتب الإلكترونية" link="/ebooks" />
    <v-row>
      <v-col v-for="(ebook, i) in ebooks" :key="i" cols="12" sm="6" lg="6">
        <ebook-card :ebook="ebook" @onFavorite="fetch" />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import EbookCard from "~/components/cards/EbookCard.vue";
import SectionHeader from "~/components/shared/SectionHeader.vue";

import { useHomeStore } from "~/stores/HomeStore";

const homeStore = useHomeStore();

const home = computed(() => homeStore.home);
const ebooks = computed(() => (home.value ? home.value.online_books : []));

const fetch = async () => {
  if (!homeStore.home) {
    await homeStore.fetchHome();
  }
};

onMounted(() => {
  fetch();
});
</script>

<style lang="scss" scoped></style>
