<template>
  <div class="home__section">
    <img
      class="bundles__bg"
      src="~/assets/images/shapes/bundles-large.svg"
      alt="Shape"
    />
    <img
      class="bundles__bg_small"
      src="~/assets/images/shapes/bundles-small.svg"
      alt="Shape"
    />
    <div v-if="homeStore.loading" class="loading-text">
      <p class="text-center">جارٍ التحميل...</p>
    </div>
    <div v-else>
      <section-header
        title="باقات"
        :sub-title="
          !isClosed ? 'بإمكانك الحصول على أكثر من مادة وبتخفيض مناسب' : null
        "
        link="/bundles"
      />
      <v-row v-if="!isClosed">
        <v-col v-for="(bundle, i) in bundles" :key="i" cols="12" sm="6" md="6">
          <bundle-card
            :bundle="bundle"
            @onFavorite="fetch(response.current_page)"
          />
        </v-col>
        <v-col cols="12">
          <!-- <div> -->
          <div class="d-flex justify-center mt-3">
            <v-pagination
              v-model="response.current_page"
              class="pagination"
              :length="response.last_page"
              :total-visible="5"
              @update:model-value="fetch(response.current_page)"
            />
          </div>
          <!-- </div> -->
        </v-col>
      </v-row>
      <closed-section model-type="الباقات" v-else />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";

import ClosedSection from "~/components/shared/ClosedSection.vue";
import SectionHeader from "~/components/shared/SectionHeader";
import BundleCard from "~/components/cards/BundleCard.vue";
import { useHomeStore } from "~/stores/HomeStore";

const homeStore = useHomeStore();

const isClosed = ref(false);

const response = computed(() => homeStore.homeBundles);

const bundles = computed(() => response.value.bundles);

const fetch = async (currentPage) => {
  await homeStore.fetchBundles({
    page: currentPage,
  });
  if (bundles.value) {
    isClosed.value = bundles.value.length === 0;
  }
};

await fetch(1); // Call fetch with the initial page number or pass any other page number as required
// onMounted(() => {
// });
</script>

<style lang="scss" scoped>
.home__section {
  position: relative;
}
.bundles-section {
  background-image: url("~/assets/images/shapes/bundles-small.svg"),
    url("~/assets/images/shapes/bundles-large.svg");
  background-size: 8%, 25%;
  background-repeat: no-repeat, no-repeat;
  background-position:
    left top,
    106% 1%;
}
.bundles__bg {
  position: absolute;
  right: -55px;
  top: -60px;
  /* width: 507px; */
  height: 117%;
  z-index: -10;
}
.bundles__bg_small {
  position: absolute;
  top: 29px;
  width: 276.9px;
  height: 276.9px;
  z-index: -10;
  // left: 150px;
  left: 7%;
}
@media (max-width: 1600px) {
  .bundles__bg_small {
    top: -2px;
    width: 222.9px;
    left: 1%;
  }
}
@media (max-width: 1264px) {
  .bundles__bg {
    right: -104px;
    top: -30px;
    /* width: 507px; */
    height: 60%;
  }
  .bundles__bg_small {
    left: -0.5%;
  }
}
@media (max-width: 991px) {
  .bundles__bg_small {
    top: -49px;
    width: 134px;
    left: -1%;
  }
}
@media (max-width: 600px) {
  .bundles__bg {
    right: -120px;
    top: -13px;
    height: 50%;
  }
  .bundles__bg_small {
    top: -66px;
    width: 100px;
    left: 0%;
  }
}
</style>
